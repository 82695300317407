import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import moment from "moment";
import localization from "moment/locale/pt-br";
import ptBR from "antd/lib/locale-provider/pt_BR";

import Routes from "routes";
import { store, persistor } from "./store";

import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";

import GlobalTheme from "./theme";
import "./index.css";

import "antd/dist/antd.css";
import "react-credit-cards/es/styles-compiled.css";
import TawkTo from "tawk";

require("dotenv").config();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (breadcrumb.category === "xhr") {
      const data = {
        requestBody: hint.xhr.__sentry_xhr__.body,
        response: hint.xhr.response,
        responseUrl: hint.xhr.responseURL,
      };
      return { ...breadcrumb, data };
    }
    return breadcrumb;
  },
});

moment.locale("pt-br", localization);

function Twak() {
  useEffect(() => {
    TawkTo("61cddd56c82c976b71c425d1", "1fo62h723");
  }, []);
  return null;
}

ReactDOM.render(
  <Provider store={store}>
    <Twak />
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <ConfigProvider locale={ptBR}>
          <ThemeProvider theme={GlobalTheme}>
            <Routes />
          </ThemeProvider>
        </ConfigProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
