import { connect } from "react-redux"; 

import ModalPro from "./modal-pro.component"; 

import { onCloseModal } from "core/redux/modal/modal.action";
   
const mapStateToProps = (state) => {
    return {
      user: state.default.user, 
      plan_active: state.professional.plan.panel, 
    };
  };

const mapDispatchToProps = {    
    onCloseModal, 
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPro);