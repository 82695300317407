import React from "react";
import { Carousel, Tag } from "antd";
import { useHistory, Link } from "react-router-dom";
import {
  ReadOutlined,
  FormOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import moment from "moment";

import Button from "core/components/button/button.component";
import { floatToCurrency } from "core/components/format-string/format-string";

import {
  BodyModal,
  TitleModal,
  StepResult,
  Step,
  IconStep,
  TitleStep,
  DescriptionStep,
  CloseModal,
  ValuesContainer,
  Value,
  Discount,
  Validate,
} from "./modal-pro.styles";

const DrawerPro = ({
  onCloseModal = () => null,
  redirect = false,
  plan_active = {},
  user = {},
}) => {
  const history = useHistory();
  const currentDate = moment.unix(
    plan_active?.items?.avaliable_plan?.discount_max_date
  );
  let params = {};
  if (redirect === true) params["to"] = "/auth/financial/transactions";
  
  return (
    <BodyModal>
      <Link {...params} onClick={() => onCloseModal()}>
        <CloseModal />
      </Link>
      <TitleModal>O que oferecemos na FluxoPay PRO?</TitleModal>
      <StepResult>
        <Carousel autoplay>
          <div>
            <Step>
              <IconStep>
                <ReadOutlined />
              </IconStep>
              <TitleStep>Relatório por agregador</TitleStep>
              <DescriptionStep>
                Veja seus lançamentos categorizados por descrição, categoria,
                centro de custo e muito mais.
              </DescriptionStep>
            </Step>
          </div>
          <div>
            <Step>
              <IconStep>
                <FileProtectOutlined />
              </IconStep>
              <TitleStep>Relatório Diário</TitleStep>
              <DescriptionStep>
                Veja seus lançamentos por dia no Relatório Diário.
              </DescriptionStep>
            </Step>
          </div>
          <div>
            <Step>
              <IconStep>
                <FormOutlined />
              </IconStep>
              <TitleStep>Anotações</TitleStep>
              <DescriptionStep>
                Crie notificações personalizadas e tenha controle das suas
                tarefas financeiras.
              </DescriptionStep>
            </Step>
          </div>
          {/*  <div>
            <Step>
              <IconStep>
                <DiffOutlined />
              </IconStep>
              <TitleStep>Extras</TitleStep>
              <DescriptionStep>
                Receba notificações e eventos por SMS e Whatsapp.
              </DescriptionStep>
            </Step>
          </div> */}
        </Carousel>
      </StepResult>
      {plan_active?.items?.avaliable_plan?.discount_max_date !== null ? (
        <Validate>
          <Tag color="error">
            Teste grátis por {currentDate.diff(moment(), "days") + 1} dias
          </Tag>
        </Validate>
      ) : null}
      <ValuesContainer
        style={{
          marginTop:
            plan_active?.items?.avaliable_plan?.discount_max_date !== null
              ? 0
              : 40,
        }}
      >
        <Value>
          {floatToCurrency(
            plan_active?.items.avaliable_plan?.monthly_amount -
              plan_active?.items.avaliable_plan?.discount_amount
          )}
        </Value>
        {plan_active?.items?.avaliable_plan?.discount_max_date !== null ? (
          <Discount>
            {floatToCurrency(
              plan_active?.items?.avaliable_plan?.monthly_amount
            )}
          </Discount>
        ) : null}
      </ValuesContainer>
      {user?.items?.type === "user" ? (
        <h5 style={{ color: "#f5222d", textAlign: "center" }}>
          Contate o administrador da sua conta para trocar de plano.
        </h5>
      ) : (
        <Button
          btntype="secondary"
          onClick={() => {
            history.push("/auth/professional/pro?active=true");
            onCloseModal();
          }}
        >
          Trocar de Plano
        </Button>
      )}
    </BodyModal>
  );
};

export default DrawerPro;
