import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Layout, Menu, Popover, Row, Col } from "antd";
import { isIOS } from "react-device-detect";
import { FaGooglePlay } from "react-icons/fa/index";
import theme from "theme.js";
import {
  LoadingOutlined,
  ClearOutlined,
  LockOutlined,
  CloudUploadOutlined
} from "@ant-design/icons";

import blackLogo from "core/assets/images/logo.png";
import whiteLogo from "core/assets/images/logo-white.png";
import ClearAccountForm from "modules/default/components/profile-forms/clear-account-form/clear-account-form.container";
import PasswordForm from "modules/default/components/profile-forms/uptade-password-form/uptade-password-form.container";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  TeamOutlined,
  RetweetOutlined,
  //AreaChartOutlined,
  FileProtectOutlined,
  ScheduleOutlined,
  ShoppingOutlined,
  LogoutOutlined,
  HistoryOutlined,
  FileExcelOutlined,
  DollarOutlined,
  SketchOutlined,
  AppleOutlined,
  GroupOutlined,
  CreditCardOutlined,
  // DisconnectOutlined,
} from "@ant-design/icons/";

import Notifications from "./notifications/notifications.container";
import Button from "core/components/button/button.component";
import ModalPro from "core/components/modal-pro/modal-pro.container";
import Notification from "../notification/notification.component";

import {
  MenuStyle,
  ContainerButtonStyles,
  HeaderStyleContent,
  FormRegisterLogo,
  CustomDrawer,
  IconMenu,
  FocusStyles,
  ButtonApp,
  PopOverItem,
  Logo,
} from "./menu.styles";

import "./menu.styles.css";

const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;
let oldValue = 0;
class MenuSlider extends React.Component {
  state = {
    collapsed: true,
    scroll: true,
    loadingClearAccount: false,
  };

  toggle = () => {
    this.setState({
      ...this.state,
      collapsed: !this.state.collapsed,
    });
  };

  onClose = (e) => {
    this.setState({
      ...this.state,
      collapsed: true,
    });
  };

  async componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll);
  }

  listenToScroll = () => {
    const newValue = window.pageYOffset;
    if (this.state.scroll !== (oldValue - newValue > 0)) {
      this.setState({
        ...this.state,
        scroll: !this.state.scroll,
      });
    }
    oldValue = newValue;
  };

  menuContent = () =>
    this.props.user?.items?.type === "client" ? (
      <Menu
        defaultOpenKeys={["sub2"]}
        theme="dark"
        mode="inline"
        data-tour="menu-main"
        defaultSelectedKeys={[this.props.location.pathname]}
        selectedKeys={[this.props.location.pathname]}
        style={{ overflow: "hidden" }}
      >
        <Menu.Item
          key="/customer/auth/"
          icon={<UserOutlined />}
          onClick={this.onClose}
        >
          Meus dados
          <Link to="/customer/auth/" />
        </Menu.Item>
        <Menu.Item
          key="/customer/auth/cards"
          icon={<CreditCardOutlined />}
          onClick={this.onClose}
        >
          Meus cartões
          <Link to="/customer/auth/cards" />
        </Menu.Item>
      </Menu>
    ) : (
      <Menu
        defaultOpenKeys={["sub2"]}
        theme="dark"
        mode="inline"
        data-tour="menu-main"
        defaultSelectedKeys={[this.props.location.pathname]}
        selectedKeys={[this.props.location.pathname]}
        style={{ overflow: "hidden" }}
      >
        {this.props?.sub_user?.items ? (
          <Menu.Item
            style={{ color: "orange" }}
            key="20"
            icon={<LogoutOutlined style={{ color: "orange" }} />}
            onClick={() => {
              this.props.removeSubUSer();
            }}
          >
            Deslogar Subconta
            <Link to="/auth/financial/transactions" />
          </Menu.Item>
        ) : null}
        {/*<Menu.Item key="/auth/financial/transactions" icon={<AreaChartOutlined />} onClick={this.onClose}>
        Dashboard
        <Link to="/auth/financial/transactions" />
      </Menu.Item>
      */}

        <Menu.Item
          data-tour="menu-item-new-issue"
          key="/auth/charges/new-issue"
          icon={<ScheduleOutlined />}
          onClick={this.onClose}
        >
          Emitir Cobrança
          <Link to="/auth/charges/new-issue" />
        </Menu.Item>
        <SubMenu
          data-tour="menu-item-financial"
          key="sub2"
          icon={<DollarOutlined />}
          title={"Financeiro"}
        >
          <Menu.Item key="/auth/financial/transactions" onClick={this.onClose}>
            Recebimentos
            <Link to="/auth/financial/transactions" />
          </Menu.Item>
          <Menu.Item key="/auth/financial/cash-flow" onClick={this.onClose}>
            Fluxo de caixa
            <Link to="/auth/financial/cash-flow" />
          </Menu.Item>
          <Menu.Item
            key="/auth/financial/income-statement"
            onClick={this.onClose}
          >
            DRE
            <Link to="/auth/financial/income-statement" />
          </Menu.Item>
          <Menu.Item
            key="/auth/financial/monthly-performance-report"
            onClick={this.onClose}
          >
            RPM
            <Link to="/auth/financial/monthly-performance-report" />
          </Menu.Item>
          <Menu.Item
            key="/auth/financial/report-by-aggregator"
            onClick={this.onClose}
          >
            RPA
            <Link to="/auth/financial/report-by-aggregator" />
          </Menu.Item>
          <Menu.Item key="/auth/financial/report-diary" onClick={this.onClose}>
            Relatório diário
            <Link to="/auth/financial/report-diary" />
          </Menu.Item>
        </SubMenu>
        <Menu.Item
          data-tour="menu-item-invoices"
          icon={<FileProtectOutlined />}
          key="/auth/charges/invoices"
          onClick={this.onClose}
        >
          Faturas
          <Link to="/auth/charges/invoices" />
        </Menu.Item>
        <Menu.Item
          key="/auth/negociations"
          icon={<RetweetOutlined />}
          onClick={this.onClose}
        >
          Negociações
          <Link to="/auth/negociations" />
        </Menu.Item>
        <Menu.Item
          key="/auth/operators"
          icon={<UserOutlined />}
          onClick={this.onClose}
        >
          Operadores
          <Link to="/auth/operators" />
        </Menu.Item>
        <Menu.Item
          key="/auth/customers"
          icon={<TeamOutlined />}
          onClick={this.onClose}
        >
          Clientes
          <Link to="/auth/customers" />
        </Menu.Item>
        <Menu.Item
          icon={<HistoryOutlined />}
          key="/auth/logs"
          onClick={this.onClose}
        >
          Logs
          <Link to="/auth/logs" />
        </Menu.Item>
        <Menu.Item
          icon={<FileExcelOutlined />}
          key="/auth/charges/massified"
          onClick={this.onClose}
        >
          Massificados
          <Link to="/auth/charges/massified" />
        </Menu.Item>
        {/*   <Menu.Item
          icon={<DisconnectOutlined />}
          key="/auth/integration"
          onClick={this.onClose}
        >
          Integração
          <Link to="/auth/integration" />
        </Menu.Item> */}
        <Menu.Item
          icon={<GroupOutlined />}
          key="/auth/plans"
          onClick={this.onClose}
        >
          Planos
          <Link to="/auth/plans" />
        </Menu.Item>
        {/*<Menu.Item icon={<FundProjectionScreenOutlined />}  key="/auth/financial/reports" onClick={this.onClose}>
 
        Relatórios
        <Link to="/auth/financial/reports" />
      </Menu.Item> 
      */}

        <Menu.Item
          icon={<CloudUploadOutlined />}
          key="/auth/imports"
          onClick={this.onClose}
        >
          Importações
          <Link to="/auth/imports" />
        </Menu.Item>
        {this.props.user?.items?.type === "god" ? (
          <SubMenu key="sub3" icon={<ShoppingOutlined />} title="Subcontas">
            {/* <Menu.Item icon={<ProfileOutlined />} key={`/auth/settings/${this.props.user?.items?.email}`} onClick={this.onClose}>
              Configuração
              <Link to={`/auth/settings/${this.props.user?.items?.root_email}`} />
            </Menu.Item>*/}
            <Menu.Item key="/auth/subaccount/accounts" onClick={this.onClose}>
              Contas
              <Link to="/auth/subaccount/accounts" />
            </Menu.Item>
          </SubMenu>
        ) : null}
      </Menu>
    );

  contentPerfil = () => {
    return (
      <div style={{ display: "flex", flexFlow: "column", gap: "8px" }}>
        {this.props.user?.items?.type === "root" ? (
          <PopOverItem to={`/auth/profile/${this.props.user?.items?.email}`}>
            <UserOutlined style={{ marginBottom: 10 }} />
            <span style={{ marginLeft: "5px" }}>Perfil</span>
          </PopOverItem>
        ) : null}
        <PopOverItem
          onClick={async () => {
            this.setState({
              ...this.state,
              loadingClearAccount: true,
            });

            const response = await this.props.getClearAccount();
            if (response === true)
              this.props.setModalProps({
                visible: true,
                close: false,
                title: "Entre com o código recebido",
                width: "400px",
                body: <ClearAccountForm />,
              });
            else Notification("error", response);
            this.setState({
              ...this.state,
              loadingClearAccount: false,
            });
          }}
        >
          {this.state.loadingClearAccount ? (
            <LoadingOutlined style={{ marginBottom: 10 }} />
          ) : (
            <ClearOutlined style={{ marginBottom: 10 }} />
          )}
          <span style={{ marginLeft: "5px" }}> Limpar conta</span>
        </PopOverItem>
        <PopOverItem
          onClick={() => {
            this.props.setDrawerProps({
              visible: true,
              title: "Alterar senha",
              placement: "right",
              width: "324px",
              body: (
                <PasswordForm
                  onClose={this.props.onCloseDrawer}
                  email={this?.props?.user?.items?.root_email || this?.props?.user?.sub_user?.items?.email}
                />
              ),
            });
          }}
        >
          <LockOutlined style={{ marginBottom: 10 }} />
          <span style={{ marginLeft: "5px" }}>Alterar senha</span>
        </PopOverItem>
        {this.props.user?.items?.type === "god" ? (
          <PopOverItem
            to={`/auth/profile/${this.props.user?.sub_user?.items?.email}`}
          >
            <UserOutlined style={{ marginBottom: 10 }} />
            <span style={{ marginLeft: "5px" }}>Perfil</span>
          </PopOverItem>
        ) : null}
        {this.props.user?.items?.type !== "client" &&
        this.props.plan_active.items.onboarding === false ? (
          <PopOverItem to={`/auth/professional/`}>
            <SketchOutlined style={{ marginBottom: 10 }} />
            <span style={{ marginLeft: "5px" }}>Meus planos</span>
          </PopOverItem>
        ) : null}
        <ContainerButtonStyles
          onClick={() => {
            this.props.logoutUser();
            if (this.props.user?.items?.type === "client")
              this.props.history.push(
                `/customer/login/${this.props.user?.items?.root_email}`
              );
          }}
        >
          <LogoutOutlined style={{ fontSize: "14px" }} />
          <span style={{ marginLeft: "5px" }}>Sair</span>
        </ContainerButtonStyles>
      </div>
    );
  };

  render() {
    const { children, user } = this.props;
    const { collapsed } = this.state;

    return (
      <MenuStyle>
        <Layout style={{ width: "100%", minHeight: "100vh" }}>
          <Sider trigger={null}>
            {
              <div
                className="logo"
                data-tour="logo-menu"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Logo imageUrl={whiteLogo} />
              </div>
            }
            <this.menuContent />
          </Sider>
          <MenuStyle style={{ width: "100%" }}>
            <Layout className="site-layout" style={{ width: "100%" }}>
              <Header
                className="site-layout-background"
                style={{ padding: 0, height: 60 }}
              >
                <HeaderStyleContent>
                  <IconMenu>
                    {React.createElement(
                      collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                      {
                        className: "trigger",
                        onClick: this.toggle,
                      }
                    )}
                  </IconMenu>
                  {user?.items?.logo ? (
                    <FormRegisterLogo
                      imageUrl={user?.items?.logo}
                    ></FormRegisterLogo>
                  ) : (
                    <FormRegisterLogo imageUrl={blackLogo} />
                  )}
                  {this.props.user?.items?.type !== "client" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        justifyContent: "center",
                      }}
                    >
                      <ButtonApp
                        scroll={this.state.scroll}
                        href={
                          isIOS
                            ? "https://apps.apple.com/app/id1573448552"
                            : "https://play.google.com/store/apps/details?id=com.fluxoresultados.fluxopay"
                        }
                      >
                        <Button
                          size="large"
                          data-tour="google-play"
                          btntype="secondaryOutlined"
                          style={{ padding: "5px 8px 3px 8px", width: "130px" }}
                          icon={isIOS ? <AppleOutlined /> : <FaGooglePlay />}
                        >
                          <div style={{ marginTop: "5px", marginLeft: "10px" }}>
                            <p
                              style={{
                                lineHeight: "0px",
                                textTransform: "uppercase",
                                fontSize: "9px",
                                fontWeight: "300",
                              }}
                            >
                              Baixe nosso app
                            </p>
                            <p
                              style={{
                                lineHeight: "5px",
                                textTransform: "capitalize",
                                fontSize: "14px",
                                height: "0px",
                              }}
                            >
                              {isIOS ? "Apple Store" : "Google Play"}
                            </p>
                          </div>
                        </Button>
                      </ButtonApp>
                      <Button
                        size="large"
                        disabled={this.props.user?.items?.type === "user"}
                        icon={
                          <SketchOutlined
                            style={{ color: theme.secondary_color }}
                          />
                        }
                        btntype="secondaryOutlined"
                        texttransform={"true"}
                        onClick={() => {
                          this.props.plan_active.items.onboarding === false
                            ? this.props.history.push("/auth/professional")
                            : this.props.setModalProps({
                                visible: true,
                                close: true,
                                title: "",
                                width: "400px",
                                body: <ModalPro />,
                              });
                        }}
                      >
                        <div>
                          {this.props.plan_active.items.onboarding === true
                            ? "FluxoPay PRO"
                            : "Meus Planos"}
                        </div>
                      </Button>
                      <Notifications />
                      <FocusStyles>
                        <Popover
                          placement="leftTop"
                          content={this.contentPerfil}
                          trigger="click"
                        >
                          <UserOutlined />
                        </Popover>
                      </FocusStyles>
                    </div>
                  ) : (
                    <FocusStyles>
                      <Popover
                        placement="leftTop"
                        content={this.contentPerfil}
                        trigger="click"
                      >
                        <UserOutlined />
                      </Popover>
                    </FocusStyles>
                  )}
                </HeaderStyleContent>
              </Header>
              <Content
                style={{
                  margin: "24px 16px",
                  padding: 24,
                  minHeight: "100%",
                  overflowY: "none",
                }}
                data-tour="content-menu"
              >
                <Row>
                  <Col span={24}>{children}</Col>
                </Row>
              </Content>
            </Layout>
          </MenuStyle>
        </Layout>
        <Row gutter={[0, 0]}>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
            <CustomDrawer
              title="Menu"
              placement={"left"}
              closable={true}
              visible={!collapsed}
              className="classd"
              onClose={this.onClose}
              width="100%"
            >
              <this.menuContent />
            </CustomDrawer>
          </Col>
        </Row>
      </MenuStyle>
    );
  }
}

export default withRouter(MenuSlider);
