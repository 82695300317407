import styled from "styled-components";
import { 
  CloseOutlined,
} from "@ant-design/icons";


export const BodyModal = styled.div`
display: flex;
justify-content: center;
flex-flow: column;
gap: 20px; 
height: auto;
padding: 20px;
`; 

export const TitleModal = styled.h2` 
font-size: 32px;
line-height: 120%;
text-align: center;
`; 

export const StepResult = styled.div`  
box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.25); 
height: 160px;
`;  

export const Step = styled.div` 
display: flex;
justify-content: center;
align-items: center;
flex-flow: column; 
color:#262626;
padding: 20px; 
`; 

export const IconStep = styled.div`   
font-size: 40px;
color:#262626;
line-height: 30px;
`;  

export const TitleStep = styled.h3``;  

export const DescriptionStep = styled.p`
font-size: 12px;
line-height: 150%;
text-align:center;
`;  

export const CloseModal = styled(CloseOutlined)`
font-size: 18px;
position: absolute;   
z-index: 9999;
cursor: pointer;
top: 15px;
right: 20px; 
color: #595959;
  &:hover {
    opacity: 0.6 !important;
  }
`;

export const ValuesContainer = styled.div` 
display: flex;
justify-content: center;
align-items: center;
gap: 8px;
`;  

export const Value = styled.h4` 
font-size: 28px;
font-weight: 700;
`;  

export const Discount = styled.h4`  
text-decoration: line-through;
`;  

export const Validate = styled.div` 
display: flex;
justify-content: center;
margin-top: 40px;

`;  
