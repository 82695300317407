import { connect } from "react-redux";
import React, { lazy, Suspense, useEffect, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Spin, Drawer, Modal, Form } from "antd";
import styled from "styled-components";
import Tour from "reactour";

// Modules
import FormTermsUse from "modules/default/components/form-terms-use/form-terms-use.container";

// Redux Components
import { logoutUser } from "modules/default/redux/crud-form-customer/crud-form-customer.actions";

// Redux Components
import Menu from "core/components/menu/menu.container";
import PrivateRoute from "core/components/private-route/private-route.container";
import Notification from "core/components/notification/notification.component";
import LoadingSpinner from "core/components/loading-spinner/loading-spinner.component";

// Redux Core
import { onCloseDrawer } from "core/redux/drawer/drawer.action";
import { onCloseModal, setModalProps } from "core/redux/modal/modal.action";
import { onCloseTour, getTours } from "core/redux/tour/tour.action";
import { postCache, getCache } from "core/redux/cache/cache.actions";
import { postToken } from "core/redux/token-validation/token-validation.action";

// Pages
const LoginPage = lazy(() =>
  import("modules/default/pages/login/login.component")
);
const RegisterPage = lazy(() =>
  import("modules/default/pages/register/register.component")
);
const CheckoutPage = lazy(() =>
  import("modules/default/pages/checkout/checkout.component")
);
/*const DashboardPage = lazy(() =>
  import("modules/default/pages/dashboard/dashboard.component")
);
*/
const NegociationsPage = lazy(() =>
  import("modules/negociations/pages/negociations/negociations.component")
);
const OperatorsPage = lazy(() =>
  import("modules/operators/pages/operators/operators.component")
);
const ReportPage = lazy(() =>
  import(
    "modules/operators/pages/activities-report/activities-report.component"
  )
);
const NewIssuePage = lazy(() =>
  import("modules/charges/pages/new-issue/new-issue.component")
);

const InvoicesPage = lazy(() =>
  import("modules/charges/pages/invoices/invoices.component")
);

const InvoicesCalendaryPage = lazy(() =>
  import("modules/charges/pages/calendary/calendary.component")
);

const MassifiedPage = lazy(() =>
  import("modules/charges/pages/massified/massified.component")
);

const ScheduledPage = lazy(() =>
  import("modules/charges/pages/scheduled/scheduled.component")
);

const CustomersPage = lazy(() =>
  import("modules/customers/pages/customers/customers.component")
);

const ReportsPage = lazy(() =>
  import("modules/financial/pages/reports/reports.component")
);

const TransactionsPage = lazy(() =>
  import("modules/financial/pages/transactions/transactions.component")
);

const CashFlowPage = lazy(() =>
  import("modules/financial/pages/cash-flow/cash-flow.component")
);

const IncomeStatement = lazy(() =>
  import("modules/financial/pages/income-statement/income-statement.component")
);

const MonthlyPerformanceReport = lazy(() =>
  import(
    "modules/financial/pages/monthly-performance-report/monthly-performance-report.component"
  )
);

const ReportByAggregator = lazy(() =>
  import(
    "modules/financial/pages/reports-by-aggregator/reports-by-aggregator.component"
  )
);

const ReportDiary = lazy(() =>
  import("modules/financial/pages/report-diary/report-diary.component")
);

const ResetPasswordCallbackPage = lazy(() =>
  import(
    "modules/default/pages/reset-password-callback/reset-password-callback.component"
  )
);

const ProfessionalPage = lazy(() =>
  import("modules/professional/pages/professional/professional.component")
);

const SubaccountPage = lazy(() =>
  import("modules/subaccount/pages/subaccount/subaccount.component")
);

const LogsPage = lazy(() =>
  import("modules/subaccount/pages/logs/logs.component")
);

const ProfilePage = lazy(() =>
  import("modules/default/pages/profile/profile.component")
);

const SettingsPage = lazy(() =>
  import("modules/default/pages/settings/settings.component")
);

const PlansPage = lazy(() =>
  import("modules/plans/pages/plans/plans.component")
);

const CardsPage = lazy(() =>
  import("modules/client/pages/cards/cards.component")
);

const ClientPage = lazy(() =>
  import("modules/client/pages/client/client.component")
);

const Imports = lazy(() =>
  import("modules/imports/pages/imports/imports.component")
);

/* const IntegrationPage = lazy(() =>
  import("modules/integration/pages/integration/integration.component")
); */

const loadingSpinner = (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin size="large" />
  </div>
);

const CustomDrawer = styled(Drawer)`
  & .ant-drawer-content-wrapper {
    @media only screen and (max-width: 992px) {
      width: 100% !important;
    }
  }
`;

const Routes = ({
  user,
  drawer,
  modal,
  tour,
  plan_active,
  cache,
  getTours,
  setModalProps,
  onCloseDrawer,
  onCloseModal,
  onCloseTour,
  postCache,
  getCache,
  logoutUser,
  postToken,
}) => {
  const [form] = Form.useForm();
  const [formTerms] = Form.useForm();

  useEffect(() => {
    if (user.items.accepted_policies === false)
      setModalProps({
        key: "modal",
        visible: true,
        close: true,
        title: "Termos de uso",
        width: "650px",
        isLogged: true,
        body: <FormTermsUse form={formTerms} form_register={form} />,
      });
  }, [setModalProps, form, formTerms, user]);

  const onGetTourCallback = useCallback(() => {
    getTours();
  }, [getTours]);

  useEffect(() => {
    if (user.is_on) onGetTourCallback();
  }, [onGetTourCallback, user]);

  const onPostTokenCallback = useCallback(async () => {
    const response = await postToken();
    if (response !== true) {
      logoutUser();
    }
  }, [postToken, logoutUser]);

  useEffect(() => {
    if (user.is_on) onPostTokenCallback();
  }, [onPostTokenCallback, user]);

  const onGetCacheCallback = useCallback(async () => {
    getCache();
  }, [getCache]);

  const onPostCacheCallback = useCallback(async () => {
    return postCache({
      clear_cache: true,
    });
  }, [postCache]);

  useEffect(() => {
    if (user.is_on) onGetCacheCallback();
  }, [user, onGetCacheCallback]);

  useEffect(() => {
    const func = async () => {
      if (!cache.loading)
        if (cache.items.clear_cache === false) {
          const newResponse = await onPostCacheCallback();
          if (newResponse === true) {
            Notification(
              "info",
              "Tivemos uma atualização recentemente e precisamos que faça login novamente!",
              "Ops! algo deu errado"
            );
          }
        }
    };
    func();
  }, [user, onPostCacheCallback, cache]);

  return (
    <Suspense fallback={loadingSpinner}>
      {tour.tours.loading || cache.loading ? (
        <LoadingSpinner style={{ height: "100vh" }} />
      ) : (
        <div>
          <Modal
            {...modal}
            onClose={onCloseModal}
            destroyOnClose={true}
            closeIcon={modal.close}
            width={modal?.width ? modal.width : 550}
            onCancel={() => {
              if (!modal.close) onCloseModal();
            }}
            footer={null}
          >
            {modal.body ? modal.body : <div></div>}
          </Modal>
          <Tour
            startAt={0}
            goToStep={0}
            scrollDuration={500}
            onAfterOpen={(target) => (document.body.style.overflowY = "hidden")}
            onBeforeClose={(target) => (document.body.style.overflowY = "auto")}
            steps={tour.steps}
            isOpen={tour.visible}
            onRequestClose={() => {
              onCloseTour();
              tour.onFinish();
            }}
          />
          <CustomDrawer
            data-tour="drawer-main"
            {...drawer}
            onClose={onCloseDrawer}
            destroyOnClose={true}
          >
            {drawer.body ? drawer.body : <div></div>}
          </CustomDrawer>

          <Switch>
            <Route
              exact
              path="/"
              render={() =>
                user.is_on && user?.items?.type === "client" ? (
                  <Redirect to="/customer/auth/" />
                ) : user.is_on ? (
                  <Redirect to="/auth/financial/transactions" />
                ) : (
                  <LoginPage />
                )
              }
            />
            <Route
              exact
              path="/login"
              render={() =>
                user.is_on && user?.items?.type === "client" ? (
                  <Redirect to="/customer/auth/" />
                ) : user.is_on ? (
                  <Redirect to="/auth/financial/transactions" />
                ) : (
                  <LoginPage />
                )
              }
            />

            <Route
              exact
              path="/customer/login/:email"
              render={() =>
                user.is_on && user?.items?.type === "client" ? (
                  <Redirect to="/customer/auth/" />
                ) : user.is_on ? (
                  <Redirect to="/auth/financial/transactions" />
                ) : (
                  <LoginPage type="customer" />
                )
              }
            />
            <Route
              exact
              path="/register"
              render={() =>
                user.is_on && user?.items?.type === "client" ? (
                  <Redirect to="/customer/auth/" />
                ) : user.is_on ? (
                  <Redirect to="/auth/financial/transactions" />
                ) : (
                  <RegisterPage />
                )
              }
            />
            <Route
              exact
              path="/reset-password-callback/:token"
              render={() =>
                user.is_on && user?.items?.type === "client" ? (
                  <Redirect to="/customer/auth/" />
                ) : user.is_on ? (
                  <Redirect to="/auth/financial/transactions" />
                ) : (
                  <ResetPasswordCallbackPage />
                )
              }
            />

            <Route exact path="/checkout/:id" render={() => <CheckoutPage />} />

            {user?.items?.type === "client" ? (
              <Route path="/customer/auth">
                <Menu>
                  <PrivateRoute
                    path="/customer/auth"
                    component={<ClientPage />}
                  />
                  <PrivateRoute
                    path="/customer/auth/cards"
                    component={<CardsPage />}
                  />
                </Menu>
              </Route>
            ) : (
              <Route path="/auth">
                <Menu>
                  <PrivateRoute path="/auth/logs" component={<LogsPage />} />
                  <PrivateRoute
                    path="/auth/operators"
                    component={<OperatorsPage />}
                  />
                  <PrivateRoute
                    path="/auth/customers"
                    component={<CustomersPage />}
                  />
                  {/*  <PrivateRoute
                    path="/auth/financial/transactions"
                    component={<DashboardPage />}
                  /> */}
                  <PrivateRoute
                    path="/auth/negociations"
                    component={<NegociationsPage />}
                  />
                  <PrivateRoute
                    path="/auth/subaccount/accounts"
                    component={<SubaccountPage />}
                  />
                  <PrivateRoute
                    path="/auth/professional"
                    component={<ProfessionalPage />}
                  />
                  <PrivateRoute
                    path="/auth/professional/pro"
                    component={<ProfessionalPage />}
                  />
                  <PrivateRoute
                    path="/auth/charges/new-issue"
                    component={<NewIssuePage />}
                  />
                  <PrivateRoute
                    path="/auth/settings/:email"
                    component={<SettingsPage />}
                  />
                  <PrivateRoute
                    path="/auth/profile/:email"
                    component={<ProfilePage />}
                  />
                  <PrivateRoute
                    path="/auth/operators/report/:id"
                    component={<ReportPage />}
                  />
                  <PrivateRoute
                    path="/auth/charges/invoices"
                    component={<InvoicesPage />}
                  />
                  <PrivateRoute
                    path="/auth/charges/invoices/calendary"
                    component={<InvoicesCalendaryPage />}
                  />
                  <PrivateRoute
                    path="/auth/charges/massified"
                    component={<MassifiedPage />}
                  />
                  <PrivateRoute
                    path="/auth/charges/scheduled"
                    component={<ScheduledPage />}
                  />
                  <PrivateRoute
                    path="/auth/financial/reports"
                    component={<ReportsPage />}
                  />
                  <PrivateRoute
                    path="/auth/financial/transactions"
                    component={<TransactionsPage />}
                  />
                  <PrivateRoute
                    path="/auth/financial/cash-flow"
                    component={<CashFlowPage />}
                  />
                  <PrivateRoute
                    path="/auth/financial/income-statement"
                    component={<IncomeStatement />}
                  />
                  <PrivateRoute
                    path="/auth/financial/monthly-performance-report"
                    component={<MonthlyPerformanceReport />}
                  />
                  <PrivateRoute
                    path="/auth/financial/report-by-aggregator"
                    component={<ReportByAggregator />}
                  />
                  <PrivateRoute
                    path="/auth/financial/report-diary"
                    component={<ReportDiary />}
                  />
                  {/*   <PrivateRoute
                    path="/auth/integration"
                    component={<IntegrationPage />}
                  /> */}

                  <PrivateRoute path="/auth/plans" component={<PlansPage />} />
                  <PrivateRoute path="/auth/imports" component={<Imports />} />
                </Menu>
              </Route>
            )}
          </Switch>
        </div>
      )}
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.default.user,
    drawer: state.core.drawer_props,
    modal: state.core.modal_props,
    tour: state.core.tour_props,
    cache: state.core.cache,
    plan_active: state.professional.plan.panel,
  };
};

const mapDispatchToProps = {
  onCloseDrawer,
  onCloseModal,
  onCloseTour,
  setModalProps,
  getTours,
  postCache,
  getCache,
  logoutUser,
  postToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
